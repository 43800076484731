export default defineNuxtRouteMiddleware(async (to, from) => {
    console.log("onboarding-needs-insurance-plan middleware to.path: ", to);

    const onboardStore = useOnboardStore();
    const { insurancePolicy } = storeToRefs(onboardStore);

    if (!insurancePolicy.value?.plan) {
        return navigateTo("/onboard/eligibility");
    }
});
